<template>
  <b-row>
    <b-col md="12">
      <b-card>
        <b-table
          class="mt-1"
          responsive="sm"
          :fields="fields"
          :items="dataList"
          striped
          fixed
        >
          <template #cell(project)="data">
            <div>
              {{ data.item.project }}
            </div>
            <small class="text-primary">{{ data.item.type }}</small>
          </template>
          <template #cell(price)="data">
            <cleave
              id="request_price"
              v-model="data.item.price"
              class="form-control"
              :raw="true"
              :options="options.price"
              placeholder="Liste Fiyatı"
            />
          </template>
          <template #cell(block)="data">
            <div>
              {{ data.item.block }}
            </div>
            <div class="font-small-2 text-warning">
              Kat: {{ data.item.floor }}
            </div>
            <div class="font-small-2 text-muted">
              No: {{ data.item.number }}
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-button
            variant="danger"
            @click="multipleSave"
          >
            <FeatherIcon icon="CheckIcon" />
            Tüm Fiyatları Güncelle
          </b-button>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BButton,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BButton,
    Cleave,
  },
  data() {
    return {
      fields: [
        {
          key: 'project',
          label: 'Proje Adı',
        },
        {
          key: 'block',
          label: 'Blok',
        },
        {
          key: 'price',
          label: 'Liste Fiyatı',
          thStyle: { width: '220px' },
        },
      ],
      dataQuery: {
        select: [
          'com_realty_number.id AS id',
          'com_realty_project.title AS project',
          'com_realty_type.title AS type',
          'com_realty_block.title AS block',
          'com_realty_number.floor AS floor',
          'com_realty_number.number AS number',
          'com_realty_number.price AS price',
        ],
        where: {
          'com_realty_number.id_com_realty_project': this.$route.params.id,
        },
      },
      options: {
        price: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
          numeralDecimalMark: ',',
          delimiter: '.',
        },
      },
    }
  },
  computed: {
    projects() {
      return this.$store.getters['realtyProjects/getRealty_sale_projects']
    },
    dataList() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numbers']
    },
    dataCount() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numbersCount']
    },
    saveStatus() {
      return this.$store.getters['realtyNumbers/getRealty_sale_numberSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
    saveStatus(val) {
      if (val.status === true) {
        this.getDataList()
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getDataList()
      }
    },
    getDataList() {
      this.$store.dispatch('realtyNumbers/realty_sale_numbersList', this.dataQuery)
    },
    multipleSave() {
      console.log(this.dataList)
      this.$store.dispatch('realtyNumbers/realty_sale_numberMultiple', this.dataList)
    },
  },
}
</script>
